<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
        v-model="dialogSettingProgramme"
        max-width="1400px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Cấu hình programme - roadmap</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSettingProgramme = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="4" class="p-0 mt-8 pr-2" id="">
                      <v-autocomplete
                        v-model="programme_id_input"
                        :items="programmes"
                        item-text="name.vi"
                        item-value="id"
                        dense
                        outlined
                        placeholder="Chọn programme"
                        @input="setInputProgrammeName()"
                      />
                    </v-col>
                    <v-col cols="7" class="p-0 mt-8">
                      <v-text-field
                        v-model="stage_name_input"
                        dense
                        outlined
                        placeholder="Stage Name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="11" class="p-0">
                      <v-text-field
                        v-model="stage_description_input"
                        dense
                        outlined
                        placeholder="Stage Description"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="m-0 p-0 text-center">
                      <v-btn
                        color="cyan"
                        class="font-weight-bold"
                        outlined
                        small
                        @click="addOrEditProgrammeRoadmap"
                      >
                        <v-icon small left>mdi-check</v-icon>
                        Lưu
                      </v-btn>
                      <v-btn
                        color="#E53935"
                        class="font-weight-bold"
                        outlined
                        small
                        @click="resetForm()"
                      >
                        <v-icon small left>mdi-close</v-icon>
                        Hủy
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <table
                    class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
                  >
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Programme Name</th>
                        <th scope="col">Stage Name</th>
                        <th scope="col">Stage Description</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template
                        v-for="(programme_roadmap, i) in programme_roadmaps"
                      >
                        <tr :key="i">
                          <td scope="row">{{ i + 1 }}</td>
                          <td>
                            <p
                              v-if="programme_roadmap.programme_name"
                              class="text-dark-75 font-weight-bolder d-block font-size-lg"
                            >
                              {{ programme_roadmap.programme_name.vi }}
                            </p>
                          </td>
                          <td>
                            <p
                              class="text-dark-75 font-weight-bolder d-block font-size-lg"
                            >
                              {{ programme_roadmap.stage_name }}
                            </p>
                          </td>
                          <td>
                            <p
                              class="text-dark-75 font-weight-bolder d-block font-size-lg"
                            >
                              {{ programme_roadmap.stage_description }}
                            </p>
                          </td>
                          <td>
                            <div style="display: flex">
                              <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                  <button
                                    class="btn btn-icon btn-light-primary btn-sm"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="editProgrammeRoadmap(i)"
                                  >
                                    <span
                                      class="svg-icon svg-icon-md svg-icon-primary"
                                    >
                                      <v-icon color=""
                                        >mdi-square-edit-outline</v-icon
                                      >
                                    </span>
                                  </button>
                                </template>
                                <span>Edit</span>
                              </v-tooltip>
                              <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                  <button
                                    class="btn btn-icon btn-light-danger btn-sm ml-4"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="deleteProgrammeRoadmap(i)"
                                  >
                                    <span
                                      class="svg-icon svg-icon-md svg-icon-primary"
                                    >
                                      <v-icon color="">mdi-delete</v-icon>
                                    </span>
                                  </button>
                                </template>
                                <span>Delete</span>
                              </v-tooltip>
                            </div>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              @click="saveProgrammeRoadmap()"
            >
              Save
            </button>
            <button
              class="btn text-uppercase mr-2 red--text"
              @click="dialogSettingProgramme = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
export default {
  name: "SettingProgramme",
  components: {
    Loading: () => import("vue-loading-overlay"),
    // draggable: () => import('vuedraggable')
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    programmes: {
      type: Array,
      default: () => [],
    },
    roadmap_id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      is_call_api: false,
      programme_roadmaps: [],
      programme_id_input: null,
      programme_name_input: null,
      stage_name_input: "",
      type_form: null,
      index_programme_roadmap: null,
      stage_description_input: "",
    };
  },
  computed: {
    dialogSettingProgramme: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    dialogSettingProgramme(val) {
      if (val) {
        this.getAllProgrammeRoadmap();
      }
    },
  },
  methods: {
    async getAllProgrammeRoadmap() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get(
          "prep-app/roadmap/" + this.roadmap_id + "/list-programme"
        );
        if (res.status === 200) {
          vm.programme_roadmaps = res.data;
          vm.type_form = "add";
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    editProgrammeRoadmap(index) {
      let programme_roadmap = JSON.parse(
        JSON.stringify(this.programme_roadmaps[index])
      );
      this.index_programme_roadmap = index;
      this.programme_id_input = programme_roadmap.programme_id;
      this.stage_name_input = programme_roadmap.stage_name;
      this.stage_description_input = programme_roadmap.stage_description;
      this.setInputProgrammeName();
      this.type_form = "update";
    },
    deleteProgrammeRoadmap(index) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.programme_roadmaps.splice(index, 1);
        }
      });
    },
    setInputProgrammeName() {
      let vm = this;
      let programme = this.programmes.find(function (programme) {
        return programme.id === vm.programme_id_input;
      });
      this.programme_name_input = JSON.parse(JSON.stringify(programme)).name;
    },
    addOrEditProgrammeRoadmap() {
      if (this.type_form === "add") {
        let data = {
          programme_id: this.programme_id_input,
          programme_name: this.programme_name_input,
          stage_name: this.stage_name_input,
          stage_description: this.stage_description_input,
          offset: this.programme_roadmaps.length + 1,
        };
        this.programme_roadmaps.push(data);
      } else if (this.type_form === "update") {
        this.programme_roadmaps[this.index_programme_roadmap].programme_id =
          this.programme_id_input;
        this.programme_roadmaps[this.index_programme_roadmap].programme_name =
          this.programme_name_input;
        this.programme_roadmaps[this.index_programme_roadmap].stage_name =
          this.stage_name_input;
        this.programme_roadmaps[
          this.index_programme_roadmap
        ].stage_description = this.stage_description_input;
      }
      this.resetForm();
    },
    resetForm() {
      this.programme_id_input = null;
      this.programme_name_input = null;
      this.stage_name_input = "";
      this.stage_description_input = "";
    },
    saveProgrammeRoadmap() {
      let vm = this;
      this.is_call_api = true;
      let data = {
        programme_roadmaps: JSON.parse(JSON.stringify(this.programme_roadmaps)),
      };
      ApiService.post(
        "prep-app/roadmap/" + this.roadmap_id + "/sync-programme",
        data
      )
        .then(function (res) {
          if (res.status === 200) {
            vm.$toasted.success("Cập nhập thành công !!", {
              theme: "toasted-primary",
              position: "top-right",
              duration: 4000,
            });
            vm.is_call_api = false;
            vm.dialogSettingProgramme = false;
          }
        })
        .catch(function (error) {
          vm.is_call_api = false;
          if (error) {
            console.log(error);
          }
        });
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
</style>

<template>
  <div>
    <loading :active.sync="is_call_api"></loading>

    <v-row justify="center">
      <v-dialog
        v-model="dialogRoadmap"
        max-width="1400px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'"
                >Add Roadmap</span
              >
              <span class="headline" v-else>Edit Roadmap</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogRoadmap = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Name:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        dense
                        v-model="name_input"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="mt-2">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Description:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <Editor v-model="description_input"></Editor>
                    </v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Price (VND):</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0 d-flex">
                      <v-text-field
                        style="max-width: 50%"
                        type="text"
                        v-model="price_input"
                        dense
                        filled
                      ></v-text-field>
                      <p class="mt-2 ml-2 text-subtitle-1">
                        ({{ price_input | formatNumber }})
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="mt-2">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6"
                        >Display entry level:</label
                      ></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-text-field
                        dense
                        v-model="display_entry_level_input"
                        type="text"
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-2">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Entry Level:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-text-field
                        dense
                        v-model="entry_level_input"
                        type="number"
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-2">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Display Target:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-text-field
                        dense
                        v-model="display_target_input"
                        type="text"
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-2">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Target:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-text-field
                        dense
                        v-model="target_input"
                        type="number"
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Product Category:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-autocomplete
                        v-model="product_category_id"
                        :items="product_categories"
                        item-text="name.vi"
                        item-value="id"
                        color="blue-grey lighten-2"
                        filled
                        chips
                        dense
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="mt-2">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Avatar:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0" id="avatar-roadmap">
                      <b-form-file
                        @change="onChange"
                        placeholder="Chọn tệp tin hoặc cancel để xóa"
                        accept="image/*"
                      ></b-form-file>
                      <template v-if="avatar_data != null">
                        <b-img
                          fluid
                          thumbnail
                          :rounded="true"
                          :src="avatar_data"
                        ></b-img>
                      </template>
                    </v-col>
                  </v-row>
                  <!--                  <v-row>-->
                  <!--                    <v-col cols="2" class="text-right p-0 m-0"><label class="text-h6">Programme:</label></v-col>-->
                  <!--                    <v-col cols="8" class="p-0 m-0 ml-4">-->
                  <!--                      <v-autocomplete-->
                  <!--                          v-model="programme_ids"-->
                  <!--                          :items="programmes"-->
                  <!--                          item-text="name.vi"-->
                  <!--                          item-value="id"-->
                  <!--                          color="blue-grey lighten-2" filled chips multiple deletable-chips class="pb-0 mb-0"-->
                  <!--                      >-->
                  <!--                      </v-autocomplete>-->
                  <!--                      <v-btn color="cyan"-->
                  <!--                             style="margin-top: -1.5rem !important;"-->
                  <!--                             class="ma-2 font-weight-bold mt-0 pt-0" outlined small-->
                  <!--                             v-if="programme_ids.length > 0"-->
                  <!--                             @click="openDialogSortProgramme"-->
                  <!--                      >-->
                  <!--                        <v-icon left>mdi-chart-timeline</v-icon> sort-->
                  <!--                      </v-btn>-->
                  <!--                    </v-col>-->
                  <!--                  </v-row>-->
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditRoadmap(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditRoadmap(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogRoadmap = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog
        v-model="dialogSortProgramme"
        scrollable
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sắp xếp lại thứ tự programme</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSortProgramme = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <draggable :list="data_draggable">
                    <ul
                      class="list-unstyled drag-css"
                      v-for="(programme, i) in data_draggable"
                      :key="i"
                    >
                      <b-media tag="li">
                        <h5 class="mt-0 mb-1" style="color: #3699ff">
                          {{ programme.name.vi }}
                        </h5>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" outlined text @click="sortProgramme"
              >Update</v-btn
            >
            <v-btn
              color="red darken-1"
              text
              @click="dialogSortProgramme = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "RoadmapForm",
  components: {
    Editor: () => import("@/components/ckeditor5/Editor.vue"),
    Loading: () => import("vue-loading-overlay"),
    draggable: () => import("vuedraggable"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    type_form: {
      type: String,
      default: "",
    },
    offset: {
      type: Number,
      default: 0,
    },
    roadmap: {},
    product_categories: {
      type: Array,
      default: () => [],
    },
    programmes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      is_call_api: false,
      roadmap_id: null,
      name_input: "",
      description_input: "",
      price_input: null,
      display_entry_level_input: null,
      entry_level_input: null,
      display_target_input: null,
      target_input: null,
      product_category_id: null,
      programme_ids: [],
      data_draggable: [],
      dialogSortProgramme: false,
      avatar_data: null,
      avatar_file: null,
    };
  },
  computed: {
    dialogRoadmap: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    dialogRoadmap(val) {
      if (val) {
        this.setDataForm();
      }
    },
  },
  methods: {
    setDataForm() {
      if (this.type_form === "add") {
        this.name_input = "";
        this.description_input = "";
        this.price_input = null;
        this.display_entry_level_input = null;
        this.entry_level_input = null;
        this.display_target_input = null;
        this.target_input = null;
        this.product_category_id = null;
        // this.programme_ids = [];
        this.avatar_data = null;
        this.avatar_file = null;
        this.setDefaultLabelAvatar();
      } else {
        let data = JSON.parse(JSON.stringify(this.roadmap));
        this.roadmap_id = data.id;
        this.name_input = data.name;
        this.description_input =
          data.description == null ? "" : data.description;
        this.price_input = data.price;
        this.display_entry_level_input = data.display_entry_level;
        this.entry_level_input = data.entry_level;
        this.display_target_input = data.display_target;
        this.target_input = data.target;
        this.product_category_id = data.category_id;
        // this.programme_ids = data.programmes.map(e => e.id);
        this.avatar_data = data.avatar;
        this.avatar_file = null;
        this.setDefaultLabelAvatar();
      }
    },
    validateData() {
      let flat = true;
      if (this.name_input === "" || this.name_input == null) {
        this.$toasted.error("Chưa nhập tên roadmap !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.price_input == null || this.price_input <= 0) {
        this.$toasted.error("Giá tiền phải lớn hơn 0 !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (
        this.entry_level_input === "" ||
        this.entry_level_input == null ||
        this.entry_level_input < 0
      ) {
        this.$toasted.error("Entry Level không được nhỏ hơn 0 !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (
        this.target_input === "" ||
        this.target_input == null ||
        this.target_input < 0
      ) {
        this.$toasted.error("Target không được nhỏ hơn 0 !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (parseFloat(this.entry_level_input) >= parseFloat(this.target_input)) {
        this.$toasted.error("Entry level phải nhỏ hơn Target !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (
        this.product_category_id === null ||
        this.product_category_id === ""
      ) {
        this.$toasted.error("Chưa chọn product category !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.avatar_file != null) {
        if (
          !(
            this.getExtensionFile(this.avatar_file) === "jpeg" ||
            this.getExtensionFile(this.avatar_file) === "jpg" ||
            this.getExtensionFile(this.avatar_file) === "png"
          )
        ) {
          this.$toasted.error("Không đúng định dạng ảnh !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      return flat;
    },
    btnAddOrEditRoadmap(type) {
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      let vm = this;
      // let data = {
      //   name: this.name_input,
      //   price: this.price_input,
      //   entry_level: this.entry_level_input,
      //   target: this.target_input,
      //   category_id: this.product_category_id,
      //   // programmes: this.programme_ids,
      // }
      // if (!(this.description_input == null || this.description_input === '')) data.description = this.description_input;
      // if (!(this.display_entry_level_input == null || this.display_entry_level_input === '')) data.display_entry_level = this.display_entry_level_input;
      // if (!(this.display_target_input == null || this.display_target_input === '')) data.display_target = this.display_target_input;
      let formData = new FormData();
      formData.append("name", this.name_input);
      formData.append("price", this.price_input);
      formData.append("entry_level", this.entry_level_input);
      formData.append("target", this.target_input);
      formData.append("category_id", this.product_category_id);
      if (!(this.description_input == null || this.description_input === ""))
        formData.append("description", this.description_input);
      if (
        !(
          this.display_entry_level_input == null ||
          this.display_entry_level_input === ""
        )
      )
        formData.append("display_entry_level", this.display_entry_level_input);
      if (
        !(this.display_target_input == null || this.display_target_input === "")
      )
        formData.append("display_target", this.display_target_input);
      if (this.avatar_file != null)
        formData.append("avatar_file", this.avatar_file);
      this.is_call_api = true;
      if (type === 1) {
        // data.offset = this.offset;
        formData.append("offset", this.offset);
        ApiService.post("prep-app/roadmap", formData)
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Thêm thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetData");
              vm.is_call_api = false;
              vm.dialogRoadmap = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        formData.append("_method", "PUT");
        ApiService.post("prep-app/roadmap/" + this.roadmap_id, formData)
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Sửa thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetData");
              vm.is_call_api = false;
              vm.dialogRoadmap = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    getExtensionFile(file) {
      let filename = file.name;
      return filename.split(".").pop().toLowerCase();
    },
    openDialogSortProgramme() {
      let vm = this;

      // let arrProgramme = this.programmes.filter((programme) => {
      //   return vm.programme_ids.includes(programme.id);
      // })

      let arrProgramme = this.programme_ids.map((id) => {
        return vm.programmes.filter((programme) => {
          return programme.id === id;
        })[0];
      });

      this.data_draggable = JSON.parse(JSON.stringify(arrProgramme));
      this.dialogSortProgramme = true;
    },
    sortProgramme() {
      this.programme_ids = this.data_draggable.map((e) => e.id);
      this.dialogSortProgramme = false;
    },
    onChange(e) {
      let file = e.target.files[0];
      let vm = this;
      if (file === undefined) {
        vm.avatar_data = null;
        vm.avatar_file = null;
        vm.setDefaultLabelAvatar();
        return;
      }
      vm.avatar_file = file;

      let reader = new FileReader();
      reader.onload = (e) => {
        vm.avatar_data = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    setDefaultLabelAvatar() {
      let ele = document.getElementById("avatar-roadmap");
      if (ele) {
        ele.firstChild.children[1].firstChild.textContent =
          "Chọn tệp tin hoặc cancel để xóa";
      }
    },
  },
};
</script>

<style scoped></style>

<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Quản lí mục roadmap</h1>
    </div>

    <div class="pt-5 pl-5 pr-5">
<!--      <v-btn-->
<!--        rounded-->
<!--        color="primary"-->
<!--        class="mb-5"-->
<!--        x-small-->
<!--        large-->
<!--        @click="openDialogRoadmap"-->
<!--      >-->
<!--        <v-icon color="white" dark>mdi-plus</v-icon-->
<!--        ><span style="color: white">Thêm roadmap</span>-->
<!--      </v-btn>-->
      <v-btn
        rounded
        color="cyan"
        class="mb-5 ml-5"
        x-small
        large
        @click="openDialogSort"
      >
        <v-icon color="white" dark>mdi-sort</v-icon
        ><span style="color: white">Sắp xếp lại các roadmap</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Avatar</th>
                    <th scope="col">Name</th>
                    <th scope="col">Category</th>
                    <th scope="col">Level</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(roadmap, i) in roadmaps">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td class="">
                        <v-img
                          v-if="roadmap.avatar"
                          :src="roadmap.avatar"
                          max-width="100"
                          max-height="70"
                          class="rounded-lg"
                        ></v-img>
                      </td>
                      <td class="">
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ roadmap.name }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          v-if="roadmap.category"
                        >
                          {{ roadmap.category.name.vi }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ roadmap.entry_level }} -> {{ roadmap.target }}
                        </p>
                      </td>
                      <td>
<!--                        <div style="display: flex">-->
<!--                          <v-tooltip left>-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                              <button-->
<!--                                class="btn btn-icon btn-light-warning btn-sm"-->
<!--                                v-bind="attrs"-->
<!--                                v-on="on"-->
<!--                                @click="editRoadmap(i)"-->
<!--                              >-->
<!--                                <span-->
<!--                                  class="svg-icon svg-icon-md svg-icon-primary"-->
<!--                                >-->
<!--                                  <v-icon color=""-->
<!--                                    >mdi-square-edit-outline</v-icon-->
<!--                                  >-->
<!--                                </span>-->
<!--                              </button>-->
<!--                            </template>-->
<!--                            <span>Edit</span>-->
<!--                          </v-tooltip>-->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-success btn-green btn-sm ml-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="openDialogSettingProgramme(roadmap.id)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-book-multiple</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Cấu hình programme</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <router-link
                                :to="{
                                  name: 'RoadmapStep',
                                  params: { id: roadmap.id },
                                }"
                                target="_blank"
                              >
                                <button
                                  class="btn btn-icon btn-light-success btn-sm ml-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <v-icon color=""
                                      >mdi-format-list-checkbox</v-icon
                                    >
                                  </span>
                                </button>
                              </router-link>
                            </template>
                            <span>Steps</span>
                          </v-tooltip>
<!--                          <v-tooltip right>-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                              <button-->
<!--                                class="btn btn-icon btn-light-danger btn-sm ml-2"-->
<!--                                v-bind="attrs"-->
<!--                                v-on="on"-->
<!--                                @click="deleteRoadmap(roadmap.id)"-->
<!--                              >-->
<!--                                <span-->
<!--                                  class="svg-icon svg-icon-md svg-icon-primary"-->
<!--                                >-->
<!--                                  <v-icon color="">mdi-delete</v-icon>-->
<!--                                </span>-->
<!--                              </button>-->
<!--                            </template>-->
<!--                            <span>Delete</span>-->
<!--                          </v-tooltip>-->
<!--                        </div>-->
                      </td>
                    </tr>
                  </template>
                  <template v-if="!roadmaps.length">
                    <tr>
                      <td colspan="5" style="text-align: center">No Data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <RoadmapForm
      :show_dialog="dialogRoadmap"
      :type_form="type_form"
      :offset="offset_input"
      :roadmap="roadmap"
      :product_categories="product_categories"
      @setDialog="(e) => (this.dialogRoadmap = e)"
      @resetData="getAllRoadmap()"
    />

    <v-row justify="center">
      <v-dialog v-model="dialogSort" scrollable persistent max-width="600px">
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sắp xếp lại thứ tự roadmap</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSort = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <draggable :list="data_draggable">
                    <ul
                      class="list-unstyled drag-css"
                      v-for="(roadmap, i) in data_draggable"
                      :key="i"
                    >
                      <b-media tag="li">
                        <h5 class="mt-0 mb-1" style="color: #3699ff">
                          {{ roadmap.name }}
                        </h5>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" outlined text @click="sortRoadmap"
              >Update</v-btn
            >
            <v-btn color="red darken-1" text @click="dialogSort = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <SettingProgramme
      :programmes="programmes"
      :roadmap_id="roadmap_id"
      :show_dialog="dialogSettingProgramme"
      @setDialog="(e) => (this.dialogSettingProgramme = e)"
    />
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import RoadmapForm from "./modal/RoadmapForm";
import Swal from "sweetalert2";
import SettingProgramme from "./modal/SettingProgramme";
export default {
  name: "RoadmapList",
  components: {
    SettingProgramme,
    RoadmapForm,
    Loading: () => import("vue-loading-overlay"),
    draggable: () => import("vuedraggable"),
  },
  data() {
    return {
      is_call_api: false,
      roadmaps: [],
      roadmap: {},
      dialogRoadmap: false,
      type_form: null,
      offset_input: null,
      product_categories: [],
      programmes: [],
      dialogSort: false,
      data_draggable: [],
      dialogSettingProgramme: false,
      roadmap_id: null,
    };
  },
  created() {
    this.getAllRoadmap();
    this.getAllProductCategory();
    this.getAllProgramme();
  },
  methods: {
    async getAllRoadmap() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get("prep-app/roadmap");
        if (res.status === 200) {
          vm.roadmaps = res.data;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    async getAllProductCategory() {
      let vm = this;
      await ApiService.get("prep-app/product-category/limit").then(function (
        response
      ) {
        if (response.status === 200) {
          vm.product_categories = response.data;
        }
      });
    },
    async getAllProgramme() {
      let vm = this;
      await ApiService.get("prep-app/programme/limit").then(function (
        response
      ) {
        if (response.status === 200) {
          vm.programmes = response.data;
        }
      });
    },
    openDialogRoadmap() {
      this.type_form = "add";
      this.offset_input = this.roadmaps.length + 1;
      this.dialogRoadmap = true;
    },
    editRoadmap(index) {
      this.type_form = "update";
      this.roadmap = this.roadmaps[index];
      this.offset_input = 0;
      this.dialogRoadmap = true;
    },
    deleteRoadmap(roadmap_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/roadmap/" + roadmap_id)
            .then(function (res) {
              if (res.status === 200) {
                vm.$toasted.success("Xóa thành công !!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.getAllRoadmap();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    openDialogSort() {
      this.data_draggable = JSON.parse(JSON.stringify(this.roadmaps));
      this.dialogSort = true;
    },
    sortRoadmap() {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want save?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          let items = this.data_draggable.map((item, index) => {
            return {
              roadmap_id: item.id,
              offset: index + 1,
            };
          });
          let data = {
            data_sort: items,
          };
          vm.is_call_api = true;
          ApiService.post("prep-app/roadmap/sort", data)
            .then(function (res) {
              if (res.status === 200) {
                vm.getAllRoadmap();
                vm.$toasted.success("Sắp xếp thành công !", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.is_call_api = false;
                vm.dialogSort = false;
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              console.log(error);
            });
        }
      });
    },
    openDialogSettingProgramme(roadmap_id) {
      this.roadmap_id = roadmap_id.toString();
      this.dialogSettingProgramme = true;
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.label.label-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}

.btn.btn-green {
  color: #66bb6a;
  background-color: #e2f2e3;
  border-color: transparent;
}
.btn.btn-green i {
  color: #66bb6a;
}
.btn.btn-green:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #66bb6a;
}
</style>
